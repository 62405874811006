import { useState, useEffect, Children } from "react";
import { motion } from "framer-motion";

import Slide from "./Slide";
import { LightIcon } from "../Icons/light";

var autoTimer;
var currentHeight;
var resize = false;

const Slider = props => {
	//items = Items to display in the container.
	//duration = how long it should wait before going to the next one. default 5000ms(5s).
	//loop = Should the items continue coming. (Ignore this for now).
	//initActiveIndex = what item should be the first one visible.

	const {
		items = 3,
		duration = 5000,
		loop = false,
		initActiveIndex = 0,
		gutter = 10,
		children = [],
		offset = true,
		arrowColour = "black",
		autoNext = true,
		dots = false,
		dotsLocation = "center",
		bulletsIcons,
		tabletBullets
	} = props;

	const [active, setActive] = useState(initActiveIndex);
	const [slideWidth, setSlideWidth] = useState(0);
	const [slideCount, setSlideCount] = useState(0);
	const [isSliderBuilt, setIsSliderBuilt] = useState(false);

	let sliderWidth = 100 / items;

	const getGutter = i => {
		return {
			left: gutter / 2,
			right: gutter / 2
		};
	};

	const checkHeight = () => {
		if (props.blockSelector) {
			const blockSelector = document.getElementById(props.blockSelector);

			if (!blockSelector) {
				return;
			}

			const slides = blockSelector.getElementsByClassName("slide");
			const slider = blockSelector.getElementsByClassName("slideHolder")[0];

			//Set sizes.

			const sHeight = slides[0].offsetHeight;
			if (currentHeight !== sHeight) {
				if (props.blockSelector !== "models_&_price") {
					slider.style.height = `${sHeight}px`;
				}
			}
		}

	}

	const buildSlider = () => {
		// Get all the components
		setIsSliderBuilt(true);

		const blockSelector = document.getElementById(props.blockSelector);

		if (!blockSelector) {
			setIsSliderBuilt(false);
			return;
		}

		const sliderSelect = blockSelector.getElementsByClassName("slideHolder");
		const slides = blockSelector.getElementsByClassName("slide");
		const slidesPacerSelect = blockSelector.getElementsByClassName("slideSpacer");

		const slider = sliderSelect && sliderSelect.length > 0 ? sliderSelect[0] : null;
		const slidesPacer = slidesPacerSelect && slidesPacerSelect.length > 0 ? slidesPacerSelect[0] : null;

		if (!slider || !slides || !slidesPacer) {
			setIsSliderBuilt(false);
			return;
		}

		const sHeight = slides[0].offsetHeight;

		// Set Slider Height.
		if (props.blockSelector == "testimonial-wrapper") {
			blockSelector.style.height = `${sHeight + 180}px`;
		}

		// Setting Slide Width
		const sWidth = slidesPacer.offsetWidth;
		slider.style.width = `${sWidth * children.length}px`;

		for (let i = 0; i < slides.length; i++) {
			const slide = slides[i];
			slide.style.width = `${sWidth}px`;
		}

		setSlideCount(slides.length);
		setSlideWidth(sWidth);

		currentHeight = sHeight;
		resize = true;
		setTimeout(() => {
			checkHeight();
		}, 250);
	};

	const handleAutoNext = () => {
		setActive(active => active + 1);
	};

	const autoNextFunc = () => {
		if (autoNext) {
			autoTimer = setInterval(() => {
				handleAutoNext();
			}, duration);
		}
	};

	useEffect(() => {
		if (active) {
			if (autoTimer) {
				if (active === slideCount) {
					setActive(0);
				}
			}
		}
	}, [active]);

	const queueSlider = () => {
		buildSlider();
	}

	const canBuildSlider = () => {
		const blockSelector = document.getElementById(props.blockSelector);

		if (!blockSelector) {
			setTimeout(() => {
				canBuildSlider();
			}, 500);
		} else if (blockSelector != null) {
			const slides = blockSelector.getElementsByClassName("slide");
			if (slides[0]) {
				setTimeout(() => {
					buildSlider();
				}, 500);
			} else {
				setTimeout(() => {
					canBuildSlider();
				}, 500);
			}
		}
	}

	useEffect(() => {
		window.addEventListener("resize", queueSlider);

		canBuildSlider();

		if (autoNext) {
			autoNextFunc();
		}

		return () => {
			window.removeEventListener("resize", queueSlider);
			clearInterval(autoTimer);
		};
	}, []);

	useEffect(() => {
		if (!isSliderBuilt) {
			canBuildSlider();
		}
	});

	const next = () => {
		if (active + 1 === slideCount) {
			setActive(0);
		} else {
			setActive(active => active + 1);
		}
		clearInterval(autoTimer);
		autoNextFunc();
	};

	const prev = () => {
		if (active === 0) {
			setActive(slideCount - 1);
		} else {
			setActive(active => active - 1);
		}
		clearInterval(autoTimer);
		autoNextFunc();
	};

	const handleChange = index => {
		setActive(active + 1 ? index : active - 1);
		clearInterval(autoTimer);
		autoNextFunc();
	};

	let myArray = children;

	const bulletsIconsfn = () => {
		let count = 0;
		let countvalue = [];

		if (dots) {
			if (tabletBullets <= 1023 && tabletBullets > 768) {
				count = 1;
			}
			for (let index = 0; index < myArray.length - count; index++) {
				countvalue.push(myArray[index]);
			}
		}

		return countvalue;
	};

	const left = {
		odd: left => ({ x: left }),
		even: left => ({ x: left }),
		resize: left => ({ x: left }),
	};

	const getPosition = () => {
		if (active === slideCount) {
			return 0;
		}
		return active * slideWidth * -1;
	}

	return (
		<div className="rowSlider">
			<div
				className="slideSpacer"
				style={{
					width: `${sliderWidth}%`
				}}
			></div>
			<div className="slideWrapper">
				<motion.div
					className="slideHolder"
					initial="odd"
					animate={active % 2 === 0 ? "even" : !resize ? "odd" : 'resize'}
					variants={left}
					custom={getPosition()}
					transition={{ duration: 0.5, type: "tween" }}
				>
					{Children.map(children, (child, i) => {
						return (
							<Slide
								width={sliderWidth}
								gutter={getGutter(i)}
								key={`${props.blockSelector}-slide-${i}`}
							>
								{child} {/* slide html data */}
							</Slide>
						);
					})}
				</motion.div>
			</div>
			<div className={`${!offset ? "contain" : ""} ${arrowColour}-text`}>
				{active !== 0 ? (
					<div
						className="navItem navLeft valign-wrapper"
						onClick={prev}
					>
						<LightIcon
							icon={{
								icon: "angle-left", size: "3x"
							}}
						/>
					</div>
				) : null}
				{active + items !== children.length &&
					children.length >= items ? (
					<div
						className="navItem navRight valign-wrapper"
						onClick={next}
					>
						<LightIcon
							icon={{
								icon: "angle-right", size: "3x"
							}}
						/>
					</div>
				) : null}
			</div>

			<div
				className={bulletsIcons ? `${bulletsIcons} ${dotsLocation}` : `bullets ${dotsLocation}`}
			>
				{bulletsIconsfn() &&
					bulletsIconsfn().map((i, index) => {
						return (
							<span
								key={index}
								onClick={() => handleChange(index)}
								className={active === index ? "active" : ""}
							>
								{}
							</span>
						);
					})}
			</div>
		</div>
	);
};

export default Slider;