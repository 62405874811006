import {
	faSync,
	faSyncAlt,
	faPlayCircle,
	faPlay,
	faPlus,
	faMinus,
	faWrench,
	faUser,
	faChevronDown,
	faSpinner,
	faBars,
	faTimes,
	faEnvelope,
	faMapMarked,
	faMapMarkedAlt,
	faRoad,
	faTools,
	faWallet,
	faGift,
	faHandshake,
	faPhoneAlt,
	faPhone,
	faDownload,
	faTable,
	faTachometerAlt,
	faSprayCan,
	faShieldAlt,
	faGamepad,
	faMotorcycle,
	faBorderStyle,
	faPencilRuler,
	faKey,
	faHandRock,
	faMeteor,
	faCouch,
	faCogs,
	faSearch,
	faAngleDown,
	faAngleUp,
	faAngleLeft,
	faAngleRight,
	faLocation,
	faStopwatch,
	faTachometer,
	faRobot,
	IconDefinition,
	IconName,
} from "@fortawesome/pro-regular-svg-icons";

import { SetIconProps } from "./types";
import { convertIconToDefinition } from "./util";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

export enum StaticIcon {
	Sync,
	SyncAlt,
	PlayCircle,
	Play,
	Minus,
	Plus,
	ChevronDown,
	User,
	Wrench,
	Spinner,
	Envelope,
	Bars,
	Times,
	MapMarkedAlt,
	MapMarked,
	Road,
	Tools,
	Wallet,
	Gift,
	Handshake,
	Phone,
	PhoneAlt,
	Download,
	Table,
	Robot,
	Tachometer,
	TachometerAlt,
	SprayCan,
	ShieldAlt,
	Gamepad,
	Motorcycle,
	BorderStyle,
	Stopwatch,
	PencilRuler,
	Key,
	RockHand,
	Meteor,
	Couch,
	Cogs,
	Search,
	AngleDown,
	AngleUp,
	AngleRight,
	AngleLeft,
	Location
};

export const icons: Record<StaticIcon, IconDefinition> = {
	[StaticIcon.Sync]: faSync,
	[StaticIcon.SyncAlt]: faSyncAlt,
	[StaticIcon.Play]: faPlay,
	[StaticIcon.PlayCircle]: faPlayCircle,
	[StaticIcon.Plus]: faPlus,
	[StaticIcon.Minus]: faMinus,
	[StaticIcon.ChevronDown]: faChevronDown,
	[StaticIcon.User]: faUser,
	[StaticIcon.Wrench]: faWrench,
	[StaticIcon.Spinner]: faSpinner,
	[StaticIcon.Envelope]: faEnvelope,
	[StaticIcon.Bars]: faBars,
	[StaticIcon.Times]: faTimes,
	[StaticIcon.MapMarked]: faMapMarked,
	[StaticIcon.MapMarkedAlt]: faMapMarkedAlt,
	[StaticIcon.Road]: faRoad,
	[StaticIcon.Tools]: faTools,
	[StaticIcon.Wallet]: faWallet,
	[StaticIcon.Gift]: faGift,
	[StaticIcon.Handshake]: faHandshake,
	[StaticIcon.Phone]: faPhone,
	[StaticIcon.PhoneAlt]: faPhoneAlt,
	[StaticIcon.Download]: faDownload,
	[StaticIcon.Table]: faTable,
	[StaticIcon.Robot]: faRobot,
	[StaticIcon.TachometerAlt]: faTachometerAlt,
	[StaticIcon.Tachometer]: faTachometer,
	[StaticIcon.SprayCan]: faSprayCan,
	[StaticIcon.ShieldAlt]: faShieldAlt,
	[StaticIcon.Gamepad]: faGamepad,
	[StaticIcon.Motorcycle]: faMotorcycle,
	[StaticIcon.BorderStyle]: faBorderStyle,
	[StaticIcon.Stopwatch]: faStopwatch,
	[StaticIcon.PencilRuler]: faPencilRuler,
	[StaticIcon.Key]: faKey,
	[StaticIcon.RockHand]: faHandRock,
	[StaticIcon.Meteor]: faMeteor,
	[StaticIcon.Couch]: faCouch,
	[StaticIcon.Cogs]: faCogs,
	[StaticIcon.Search]: faSearch,
	[StaticIcon.AngleDown]: faAngleDown,
	[StaticIcon.AngleUp]: faAngleUp,
	[StaticIcon.AngleLeft]: faAngleLeft,
	[StaticIcon.AngleRight]: faAngleRight,
	[StaticIcon.Location]: faLocation,
}

export const iconStrings: Map<IconName, StaticIcon> = new Map([
	['sync', StaticIcon.Sync],
	['sync-alt', StaticIcon.SyncAlt],
	['play', StaticIcon.Play],
	['play-circle', StaticIcon.PlayCircle],
	['plus', StaticIcon.Plus],
	['minus', StaticIcon.Minus],
	['chevron-down', StaticIcon.ChevronDown],
	['user', StaticIcon.User],
	['wrench', StaticIcon.Wrench],
	['spinner', StaticIcon.Spinner],
	['envelope', StaticIcon.Envelope],
	['bars', StaticIcon.Bars],
	['times', StaticIcon.Times],
	['map-marked', StaticIcon.MapMarked],
	['map-marked-alt', StaticIcon.MapMarkedAlt],
	['road', StaticIcon.Road],
	['tools', StaticIcon.Tools],
	['wallet', StaticIcon.Wallet],
	['gift', StaticIcon.Gift],
	['handshake', StaticIcon.Handshake],
	['phone-alt', StaticIcon.PhoneAlt],
	['phone', StaticIcon.Phone],
	['download', StaticIcon.Download],
	['table', StaticIcon.Table],
	['robot', StaticIcon.Robot],
	['tachometer-alt', StaticIcon.TachometerAlt],
	['tachometer', StaticIcon.Tachometer],
	['spray-can', StaticIcon.SprayCan],
	['shield-alt', StaticIcon.ShieldAlt],
	['gamepad', StaticIcon.Gamepad],
	['motorcycle', StaticIcon.Motorcycle],
	['border-style', StaticIcon.BorderStyle],
	['pencil-ruler', StaticIcon.PencilRuler],
	['key', StaticIcon.Key],
	['hand-rock', StaticIcon.RockHand],
	['meteor', StaticIcon.Meteor],
	['couch', StaticIcon.Couch],
	['cogs', StaticIcon.Cogs],
	['search', StaticIcon.Search],
	['angle-down', StaticIcon.AngleDown],
	['angle-up', StaticIcon.AngleUp],
	['angle-left', StaticIcon.AngleLeft],
	['angle-right', StaticIcon.AngleRight],
	['location', StaticIcon.Location],
	['stopwatch', StaticIcon.Stopwatch],
]);

export const getIconDefinition = (iconClass: IconName): IconDefinition => {
	const enumVal = iconStrings.get(iconClass);

	return icons[enumVal];
}

export const RegularIcon = ({ icon, style, className = "", fixedWidth = false, spin = false }: SetIconProps<IconName>) => {
	const iconDefinition = convertIconToDefinition<IconName, IconDefinition>(icon, getIconDefinition);

	return (
		<FontAwesomeIcon
			icon={iconDefinition}
			size={icon.size || "lg"}
			className={className}
			style={style}
			fixedWidth={fixedWidth}
			spin={spin}
		/>
	)
}

export { type IconName };